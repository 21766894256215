// site wide
export const Logo = require("./own_pain.svg")
export const LogoFooter = require("./logo--footer.png")

// home page
export const HomeHeader = require("./home_header.png")
export const VideoHolder = require("./video-holder.png")

export const Home01 = require("./home--1.png")
export const Home02 = require("./home--2.png")
export const Home03 = require("./home--3.png")
export const Home04 = require("./home--4.png")
export const Home05 = require("./home--5.png")
export const ArmLeft = require("./arm-elbow-bent_yellow.png")
export const ArmRight = require("./arm-pointing.png")

// holding site
export const Holding01 = require("./holding/image01.png")
export const Holding02 = require("./holding/image02.png")
export const Holding03 = require("./holding/image03.png")
export const Holding04 = require("./holding/image04.png")
export const Holding05 = require("./holding/image05.png")
export const Holding06 = require("./holding/image06.png")
export const Holding07 = require("./holding/image07.png")
export const Holding08 = require("./holding/image08.png")
export const ORUK_logo = require("./holding/oruk-logo.png")
export const Mobile = require("./holding/mobile.png")
export const ORUK_logo_grey = require("./oruk-logo_grey.png")
